/*
    DEMO STYLE
*/

/*@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
  font-family: 'Poppins', sans-serif;
  background: #fafafa;
}
  */

  .backLigth {
    background-color: PaleTurquoise;
  }
  
#sidebar p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

#sidebar
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

/*.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}*/

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: #7386D5;
  color: #fff;
  transition: all 0.3s;
  overflow: auto;
}

  #sidebar.active {
    margin-left: -250px;
  }

  #sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
  }

  #sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
  }

  #sidebar ul p {
    color: #fff;
    padding: 10px;
  }

  #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
  }

    #sidebar ul li a:hover {
      color: #7386D5;
      background: #fff;
    }

  #sidebar ul li.active > a,
  a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
  }

  #sidebar a[data-toggle="collapse"] {
    position: relative;
  }

  #sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  #sidebar ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
  }

  #sidebar ul.CTAs {
    padding: 20px;
  }

    #sidebar ul.CTAs a {
      text-align: center;
      font-size: 0.9em !important;
      display: block;
      border-radius: 5px;
      margin-bottom: 5px;
    }

  #sidebar a.download {
    background: #fff;
    color: #7386D5;
  }

  #sidebar a.article,
  a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
  }

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.content-wrapper {
  flex: 1;
  position: relative;
}

.root-editor-row {
  height: 100%;
  width: 100%;
  position: absolute;
}

.root-editor-column {
  height: 100%;
  overflow: hidden;
}

.root-editor-scroll {
    height: 100%;
    overflow-y: scroll;
    width: 100%;
}

.paradigm-scroll {
  position: absolute; 
  height: 100%;
    overflow-y: scroll;
    width: 100%;
}

#content {
  width: calc(100% - 250px);
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  /*rezo*/
  display: flex;
  flex-direction: column;
}

  #content.active {
    width: 100%;
  }

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }

    #sidebar.active {
      margin-left: 0;
    }

  #content {
    width: 100%;
  }

    #content.active {
       /*rezo*/
      /* width: calc(100% - 250px); */
       /*rezo*/
      left: 250px;
    }

  #sidebarCollapse span {
    display: none;
  }
}

